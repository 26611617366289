/* Neusa with Weights */
@font-face {
  font-family: Neusa;
  font-weight: 300;
  src: url('./Neusa/NeusaNextStd-CondensedLight.woff2') format('woff2');
}

@font-face {
  font-family: Neusa;
  font-weight: 500;
  src: url('./Neusa/NeusaNextStd-CondensedMedium.woff2')
    format('woff2');
}

@font-face {
  font-family: Neusa;
  font-weight: normal;
  src: url('./Neusa/NeusaNextStd-CondensedRegular.woff2')
    format('woff2');
}

@font-face {
  font-family: Neusa;
  font-weight: bold;
  src: url('./Neusa/NeusaNextStd-CondensedBold.woff2') format('woff2');
}

/* ProximaNova with Weights */
@font-face {
  font-family: Inter;
  font-weight: 100;
  src: url('./ProximaNova/ProximaNova-Light.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-weight: 300;
  src: url('./ProximaNova/ProximaNova-RegularItalic.woff2')
  format('woff2');
}

@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url('./ProximaNova/ProximaNova-Medium.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url('./ProximaNova/ProximaNova-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-weight: normal;
  src: url('./ProximaNova/ProximaNova-Regular.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-weight: bold;
  src: url('./ProximaNova/ProximaNova-Bold.woff2') format('woff2');
}

/* Inter with Weights */
@font-face {
  font-family: Inter;
  font-weight: 100;
  src: url('./Inter/Inter-Light.ttf');
}

@font-face {
  font-family: Inter;
  font-weight: 300;
  src: url('./Inter/Inter-Regular.ttf')
  format('woff2');
}

@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url('./Inter/Inter-Medium.ttf');
}

@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url('./Inter/Inter-SemiBold.ttf');
}

@font-face {
  font-family: Inter;  
  font-weight: normal;
  src: url('./Inter/Inter-Regular.ttf');  
}

@font-face {
  font-family: Inter;
  font-weight: bold;
  src: url('./Inter/Inter-Bold.ttf');
}