body {
  margin: 0;
  padding: 0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  /* requested added by Ivanna */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

#root {
  min-height: 100vh;
}

#noIE {
  margin: 24px;
}

.ui.image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider .ui.image {
  display: inline-block;
  position: relative;
  bottom: 1px;
}
.ui.image svg {
  width: 100%;
  height: 100%;
}

.button.disabled path {
  fill: #BDBDBD;
}

/* start: Zuora iframe CSS */
#zuora_payment {
  max-width: 515px;
  margin-left: -40px;
}

#zuora_payment > iframe {
  background-color: #ffffff;
}
/* end: Zuora iframe CSS */
